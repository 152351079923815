@use '@cloudscape-design/design-tokens' as awsui;

.tree-select {
  max-width: 100%;
  position: relative;

  .trigger {
    align-items: center;
    background-color: awsui.$color-background-input-default;
    border: 2px solid awsui.$color-border-input-default;
    border-collapse: initial;
    border-spacing: 0;
    border-radius: awsui.$border-radius-input;
    border-spacing: 0;
    box-sizing: border-box;
    caption-side: top;
    color: awsui.$color-text-body-default;
    cursor: auto;
    direction: inherit;
    display: flex;
    empty-cells: show;
    font-family: awsui.$font-family-base;
    font-size: awsui.$font-size-body-m;
    font-style: normal;
    font-stretch: normal;
    font-variant: normal;
    font-weight: 400;
    inline-size: 100%;
    justify-content: space-between;
    letter-spacing: normal;
    line-height: awsui.$line-height-body-m;
    min-block-size: 32px;
    padding-block: awsui.$space-scaled-xxs;
    padding-inline: awsui.$space-field-horizontal;
    position: relative;
    tab-size: 8;
    text-align: start;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    word-spacing: normal;

    .placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: awsui.$color-text-input-placeholder;
      font-style: italic;
    }

    &:disabled {
      background-color: awsui.$color-background-input-disabled;
      border-color: awsui.$color-background-input-disabled;

      .placeholder {
        color: awsui.$color-text-input-disabled;
      }
    }
  }

  .dropdown {
    border-radius: awsui.$border-radius-dropdown;
    border: 2px solid awsui.$color-border-input-default;
    box-shadow: 0px 4px 20px 1px rgba(0, 7, 22, 0.1);
    box-sizing: border-box;
    color: awsui.$color-text-body-default;
    font-family: awsui.$font-family-base;
    font-size: awsui.$font-size-body-m;
    font-weight: 400;
    max-height: 500px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    transform-origin: top;
    transform: translateY(0px);
    transition: transform 135ms;
    visibility: hidden;
    white-space: nowrap;
    width: 100%;
    z-index: 5000;

    [data-pc-section='filtercontainer'] {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      border-bottom: 1px solid awsui.$color-border-divider-default;
      padding-left: awsui.$space-scaled-xxs;

      [data-pc-section='input'] {
        padding-right: 0;
        border: 0;

        &:enabled:focus {
          box-shadow: 0 0 0 0;
        }
      }
    }

    ul {
      li {
        padding: 0;
        & > div {
          border-bottom: 1px solid awsui.$color-border-divider-default;
        }
      }
    }

    &.active {
      transform: translateY(1px);
      visibility: visible;
    }
  }
}
