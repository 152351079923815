@use '@cloudscape-design/design-tokens/index' as awsui;

$viewport-breakpoint-s: 957px;

.landing-page-header {
  background-color: awsui.$color-background-home-header;

  .category,
  .sub-title {
    color: awsui.$color-text-home-header-secondary;
  }

  .title {
    color: awsui.$color-text-home-header-default;
  }
}

.image-placeholder:before {
  content: 'X';
  display: block;
  background-color: awsui.$color-background-input-disabled;
  color: awsui.$color-background-container-content;
  text-align: center;
  font-size: 400px;
  line-height: 400px;
}

@media only screen and (min-width: $viewport-breakpoint-s) {
  .side-content {
    margin-top: -86px;
  }
}
